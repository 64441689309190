.errorpage {
    width: 100vw;
    height: 100vh;
    background: radial-gradient(circle, var(--mantine-color-primary-7) 0%, var(--mantine-color-primary-9) 75%);
    overflow: hidden;

    & .error {
        width: 100%;
        height: 100%;
        background-image:
                radial-gradient(circle at 1px 1px, var(--mantine-color-primary-9) 1px, transparent 0),
                radial-gradient(circle at calc(var(--mantine-custom-components-header-height) / 4) calc(var(--mantine-custom-components-header-height) / 4), var(--mantine-color-primary-9) 1px, transparent 0);
        background-size: calc(var(--mantine-custom-components-header-height) / 2) calc(var(--mantine-custom-components-header-height) / 2);

        & .logo {
            fill: var(--mantine-color-primary-6);
            opacity: 0.38;
            width: 20vw;
            /*animation: heartbeat 900ms ease infinite;*/

            @media (max-width: 48em) {
                width: 50vw;
            }
        }
    }
}

.modalroot {
    color: var(--mantine-color-primary-9) !important;
}

.modalinner {
}

.modalheader {
    background-color: var(--mantine-color-body) !important;
    padding: 0 !important;
    /*background: transparent !important;*/
}

.modalcontent {
    background-color: var(--mantine-color-body) !important;
    /*background: transparent !important;*/
}

.modalbody {
    padding-top: var(--mantine-spacing-lg) !important;
    padding-right: var(--mantine-spacing-md) !important;
    /*background-color: var(--mantine-color-tertiary-6) !important;*/
    /*border-radius: var(--mantine-radius-default) !important;*/
    /*padding-top: var(--mantine-spacing-md) !important;*/
}

.anchor {
    color: var(--mantine-color-white)  !important;
    /*font-size: var(--mantine-font-size-sm) !important;*/
}