.modalroot {
    color: var(--mantine-color-secondary-9) !important;
}

.modalinner {
}

.modalheader {
    background-color: var(--mantine-color-body) !important;
    /*background: transparent !important;*/
}

.modalcontent {
    background-color: var(--mantine-color-body) !important;
    /*background: transparent !important;*/
}

.modalbody {
    /*padding-top: var(--mantine-spacing-lg) !important;*/
    /*padding-right: var(--mantine-spacing-md) !important;*/
    /*background-color: var(--mantine-color-tertiary-6) !important;*/
    /*border-radius: var(--mantine-radius-default) !important;*/
    /*padding-top: var(--mantine-spacing-md) !important;*/
}

& .sponsorpicture {

    max-height: 100%;

    & svg {
        fill: var(--mantine-color-gray-4) !important;
    }
}

.sponsorinputlabel {

    font-size: var(--mantine-font-size-sm) !important;
    font-weight: 700 !important;
    padding-bottom: var(--mantine-font-size-xs) !important;
}

.sponsorinputinput {

    color: var(--mantine-color-secondary-9) !important;
    border-color: var(--mantine-color-secondary-2) !important;
    /*background-color: var(--mantine-color-secondary-outline-hover) !important;*/
    height: 38px !important;

    &:focus {
        border-color: var(--mantine-color-secondary-6) !important;
    }

    &::placeholder {
        color: var(--mantine-color-secondary-4) !important;
    }

    &::-ms-input-placeholder {
        color: var(--mantine-color-secondary-4) !important;
    }
}

.sponsorcheckboxinput {
    border: 1px solid var(--mantine-color-secondary-6) !important;
}

.sponsorslinkicon {
    width: 60px;
    height: 100%;
    margin-left: calc(var(--mantine-spacing-lg) * 1.5);
    margin-right: calc(var(--mantine-spacing-lg) * 1.5);
    color: var(--mantine-color-secondary-6);
}