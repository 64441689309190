.modalroot {
    /*color: var(--mantine-color-white) !important;*/
}

.modalinner {
}

.modalheader {
    /*background-color: var(--mantine-color-tertiary-6) !important;*/
    /*padding: 0 !important;*/
    /*background: transparent !important;*/
    padding: 0 !important;
}

.modalcontent {
    /*background-color: var(--mantine-color-tertiary-6) !important;*/
    /*background: transparent !important;*/
}

.modalbody {
    /*padding-top: var(--mantine-spacing-lg) !important;*/
    /*padding-right: calc(var(--mantine-spacing-xs) / 4) !important;*/
    /*padding-bottom: calc(var(--mantine-spacing-xs) / 4) !important;*/
    /*background-color: var(--mantine-color-tertiary-6) !important;*/
    /*border-radius: var(--mantine-radius-default) !important;*/
    /*padding-top: var(--mantine-spacing-md) !important;*/
    padding: 0 !important;
}

.featureslinkicon {
    width: 60px;
    height: 100%;
    margin-left: calc(var(--mantine-spacing-lg) * 1.5);
    margin-right: calc(var(--mantine-spacing-lg) * 1.5);
    color: var(--mantine-color-secondary-6);
}

.table {

    & thead {

        & tr {

            & th {

                &:first-child {
                    padding-left: var(--mantine-spacing-md) !important;
                }

                &:last-child {
                    padding-right: var(--mantine-spacing-lg) !important;
                }
            }
        }
    }

    & tbody {

        & tr {
            height: 30px;

            & td {

                border-radius: 0 !important;

                &:first-child {
                    padding-left: var(--mantine-spacing-md) !important;
                }

                &:last-child {
                    padding-right: var(--mantine-spacing-lg) !important;
                }
            }
        }
    }
}

.scrollbar {

    &,
    &:hover {
        background-color: transparent !important;
    }

    &[data-orientation='vertical'], &[data-orientation='horizontal'] {

        & .thumb {
            background-color: var(--mantine-color-gray-2) !important;

            &:before {
                background-color: var(--mantine-color-gray-2) !important;
            }
        }
    }
}

.corner {
    background-color: transparent !important;
}