.typesimagewithzoomroot {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    border-radius: 50%;

    & .typesimagewithzoomimage {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        filter: contrast(1.1) brightness(0.95);
    }

    /*& .imagewithzoomoverlay {*/
    /*    position: relative;*/
    /*    bottom: 0;*/
    /*    left: 0;*/
    /*    width: 100%;*/
    /*    height: 100%;*/

    /*    & .imagewithzoomtitle {*/
    /*        position: absolute;*/
    /*        padding-top: 100%;*/
    /*        background-color: #9E86ED;*/
    /*        color: #EF2AC1;*/
    /*        width: 100%;*/
    /*        height: 100%;*/
    /*        z-index: 1000000;*/
    /*    }*/
    /*}*/
}

.typesimagewithzoomnoimage {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: var(--mantine-color-primary-light-hover);
}

.detailsimagewithzoomroot {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: var(--mantine-radius-md);
    background-color: var(--mantine-color-tertiary-outline-hover);
    padding-bottom: 56.25%; /*Aspect ratio 16:9 (9 / 16 = 0.5625)*/

    & .detailsimagewithzoomimage {
        position: absolute;
        width: 100%;
        height: 100%;
        filter: contrast(1.1) brightness(0.95);
    }

    & .detailsimagewithzoomnoimage {
        width: 100%;
        height: 100%;

        border: none;

        & svg {
            position: absolute;
            top: calc(35%);
            left: 50%;
            transform: translate(-50%, -50%); /* Center */
            & path {
                fill: var(--mantine-color-tertiary-light-hover)
            }
        }
    }

    & .detailsimagewithzoomtitle {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
    }
}

.addinputnode {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center */

    & .addinputnodebutton {
        font-size: 2rem;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.nodebase {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.inputtitle {
    position: absolute;
    min-width: 50%;
    top: calc(100% + 17px);
    pointer-events: none;
    border-radius: 4px;
    padding: var(--mantine-spacing-xs);
}

.intermediatetitle {
    position: absolute;
    min-width: 50%;
    top: calc(100% + 10px);
    pointer-events: none;
    border-radius: 4px;
    padding: var(--mantine-spacing-xs);
}

.detailsscrolltabtabroot {
    margin-right: 1px !important;
    margin-left: 1px !important;
    min-height: 38px !important;
    color: var(--mantine-color-tertiary-2) !important;
    background-color: transparent !important;

    &:hover {
        color: var(--mantine-color-tertiary-9) !important;
        background-color: rgb(237, 245, 250) !important; /*var(--mantine-color-tertiary-outline-hover)*/
    }

    &[data='selected'] {

        color: var(--mantine-color-tertiary-9) !important;
        background-color: rgb(237, 245, 250) !important; /*var(--mantine-color-tertiary-outline-hover)*/

        & svg {
            fill: var(--mantine-color-tertiary-6) !important;
        }

        &:hover {
            /*color: var(--mantine-color-tertiary-6) !important;*/
            /*background-color: var(--mantine-color-primary-0) !important;*/

            & svg {
                fill: var(--mantine-color-tertiary-6) !important;
            }
        }
    }

    & svg {
        fill: var(--mantine-color-tertiary-0) !important;
    }
}

.scrollbar {

    &,
    &:hover {
        background-color: transparent !important;
    }

    &[data-orientation='horizontal'],
    &[data-orientation='vertical'] {

        & .thumb {
            background-color: var(--mantine-color-tertiary-9);

            &:before {
                background-color: var(--mantine-color-tertiary-9);
            }
        }
    }
}

.corner {
    width: 0;
    height: 38px;
    background-color: #1f1f1f;
}


.detailsentitiestable {
    color: var(--mantine-color-tertiary-9) !important;
    font-size: var(--mantine-font-size-sm) !important;

    & th {
        background-color: rgb(225, 241, 247) !important; /* container color */
        height: 38px !important;
        font-size: var(--mantine-font-size-sm) !important;
    }

    & tbody {

        & tr {

            &.detailsentitiestablecolored {
                & td {
                    font-weight: 700;
                    background-color: var(--mantine-color-tertiary-outline-hover) !important;
                    padding: 10px !important;
                }
            }

            &.detailsentitiestablesmall {
                & td {
                    padding-left: var(--mantine-spacing-xs) !important;
                    padding-right: var(--mantine-spacing-xs) !important;
                    padding-top: 4px !important;
                    padding-bottom: 4px !important;
                }
            }

            & td {

                &:first-child {
                    border-top-left-radius: 4px !important;
                    border-bottom-left-radius: 4px !important;
                }

                &:last-child {
                    border-top-right-radius: 4px !important;
                    border-bottom-right-radius: 4px !important;
                }

            }
        }
    }
}

.detailsentitytable {
    color: var(--mantine-color-tertiary-9) !important;
    font-size: var(--mantine-font-size-sm) !important;

    & th {
        background-color: rgb(225, 241, 247) !important; /* container color */
        height: 38px !important;
        font-size: var(--mantine-font-size-sm) !important;
    }

    & tbody {

        & tr {

            & td {
                padding: 10px !important;

                &:first-child {
                    border-top-left-radius: 4px !important;
                    border-bottom-left-radius: 4px !important;
                }

                &:last-child {
                    border-top-right-radius: 4px !important;
                    border-bottom-right-radius: 4px !important;
                }
            }
        }
    }
}

.detailsmoleculartable {
    color: var(--mantine-color-tertiary-9) !important;
    font-size: var(--mantine-font-size-sm) !important;

    & th {
        background-color: rgb(225, 241, 247) !important; /* container color */
        height: 38px !important;
        font-size: var(--mantine-font-size-sm) !important;
    }

    & tbody {

        & tr {

            & td {
                padding: 10px !important;
                cursor: pointer;

                &:first-child {
                    border-top-left-radius: 4px !important;
                    border-bottom-left-radius: 4px !important;
                }

                &:last-child {
                    border-top-right-radius: 4px !important;
                    border-bottom-right-radius: 4px !important;
                }
            }
        }
    }
}

.detailssarchinput {

    color: var(--mantine-color-tertiary-9) !important;
    border-color: transparent !important;
    background-color: transparent !important;
    font-size: var(--mantine-font-size-sm) !important;
    border-radius: 4px !important;
    height: 38px !important;

    &:focus {
        color: var(--mantine-color-tertiary-9) !important;
        border-color: var(--mantine-color-tertiary-4) !important;
        background-color: var(--mantine-color-tertiary-light-hover) !important;

        &::placeholder {
            color: var(--mantine-color-tertiary-4) !important;
            font-weight: 400 !important;
        }

        &::-ms-input-placeholder {
            color: var(--mantine-color-tertiary-4) !important;
            font-weight: 400 !important;
        }
    }

    &::placeholder {
        color: var(--mantine-color-tertiary-9) !important;
        font-weight: 700 !important;
    }

    &::-ms-input-placeholder {
        color: var(--mantine-color-tertiary-9) !important;
        font-weight: 700 !important;
    }
}

.rangeslidertrack {

    &::before {
        background-color: var(--mantine-color-tertiary-2) !important;
        opacity: 0.20 !important;
    }
}

.rangeslidermarklabel {
    /*color: var(--mantine-color-black) !important;*/
    color: var(--mantine-color-tertiary-9) !important;
    font-size: var(--mantine-font-size-xs) !important;
    font-weight: 400;
}

.rangeslidermark {
    background-color: var(--mantine-color-white) !important;
    border: 1px solid var(--mantine-color-tertiary-2) !important;
}

.detailspaginationcontrol {

    font-size: var(--mantine-font-size-sm) !important;
    font-weight: 700 !important;
    border: transparent !important;
    background-color: var(--mantine-color-tertiary-outline-hover) !important;
    color: var(--mantine-color-tertiary-9) !important;
    border-radius: 4px !important;

    &:hover {
        background-color: var(--mantine-color-tertiary-light-hover) !important;
    }

    &[data-active="true"] {
        color: var(--mantine-color-white) !important;
        background-color: var(--mantine-color-tertiary-6) !important;
    }

    &[data-disabled="true"] {
        background-color: transparent !important;
    }
}

.detailspaginationdots {
    color: var(--mantine-color-tertiary-9) !important;
}