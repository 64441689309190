.menutarget {
    /*border: 2px solid var(--mantine-color-secondary-6) !important;*/
}

.login {
    cursor: pointer;
}

.anchor {
    color: var(--mantine-color-white)  !important;
    /*font-size: var(--mantine-font-size-sm) !important;*/
}